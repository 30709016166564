import { FC, useMemo } from "react";
import cx from "classnames";

import styles from "./Whitelabel.module.scss";
import { WebsiteLogoHorizontal, WebsiteLogoShort } from "src/icons";

type Props = {
  className?: string;
  isShort?: boolean;
  size?: "small" | "medium" | "large";
};

const PROJECT_LANDING_PAGE_URL = "https://www.mytelescope.io/";

export const WhitelabelLink: FC<Props> = ({
  className,
  isShort = false,
  size = "medium",
}) => {
  const logo = useMemo<JSX.Element>(
    () => (isShort ? <WebsiteLogoShort /> : <WebsiteLogoHorizontal />),
    [isShort],
  );

  return (
    <div className={cx(className, styles.wrapper, styles[`wrapper_${size}`])}>
      {!isShort && <span className={styles.label}>Powered by</span>}
      <a href={PROJECT_LANDING_PAGE_URL} target="_blank" rel="noreferrer">
        {logo}
      </a>
    </div>
  );
};
