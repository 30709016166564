import { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Login.module.scss";
import context from "../../../context";
import { Input, WhitelabelLink } from "../../../components";
import { useTemporaryErrors } from "../../../hooks";
import { WhitelabelLogoVertical } from "../../../icons";
import { withError } from "../../../hocs";
import { logIn, logInByToken } from "../../../store/actions";
import { getUserDataByEmail, getUserIdByEmail } from "../../../store/api";
import { getQueryParamFromUrl } from "../../../utils";
import { showToastNotification } from "../../../components/ToastNotification/utils";

const InputWithError = withError(Input);

const Login = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { setIsGlobalPreloaderShown } = useContext(context);

  useEffect(() => {
    const token = getQueryParamFromUrl("token");
    if (!token) return;

    (async () => {
      await logInByToken(token);
    })();
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRemembered, setIsRemembered] = useState(
    () =>
      !localStorage["Telescope.isAuthRemembered"] ||
      localStorage["Telescope.isAuthRemembered"] === "true",
  );

  const { errors, setErrors } = useTemporaryErrors(3000);

  useEffect(() => {
    localStorage["Telescope.isAuthRemembered"] = isRemembered;
  }, [isRemembered]);

  function validate() {
    const validationErrors: typeof errors = {};
    if (!email.trim().length)
      validationErrors.email = t("login validation error");
    if (!password.trim().length)
      validationErrors.password = t("login validation error");
    return validationErrors;
  }

  async function handleSubmitForm() {
    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    setIsGlobalPreloaderShown(true);
    try {
      const userData = await getUserDataByEmail(email.trim());

      const { isVerified } = userData || {};

      if (typeof isVerified === "boolean" && !isVerified) {
        const userId = await getUserIdByEmail(email.trim());
        if (userId) {
          history.push(`/verify/${userId}`);
        }
      } else {
        await logIn(email.trim(), password.trim(), isRemembered);
      }
    } catch (error) {
      showToastNotification({
        type: "error",
        text: error.message || t("request_error"),
      });
    }
    setIsGlobalPreloaderShown(false);
  }

  return (
    <div className={styles.login}>
      <div className={styles.logoWrapper}>
        <WhitelabelLogoVertical className={styles.logo} />
      </div>
      <form
        name="logIn"
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitForm();
        }}
      >
        <InputWithError
          className={styles.input}
          value={email}
          changeHandler={(value) => setEmail(value)}
          placeholder={t("email")}
          error={errors.email}
        />
        <InputWithError
          className={styles.input}
          type="password"
          value={password}
          changeHandler={(value) => setPassword(value)}
          placeholder={t("logination_password")}
          error={errors.password}
        />
        <div className={styles.checkboxAndLinkWrapper}>
          <div className={styles.checkbox}>
            <input
              type="checkbox"
              id="isRemembered"
              checked={isRemembered}
              onChange={() => setIsRemembered((prevValue) => !prevValue)}
            />
            <label htmlFor="isRemembered">{t("remember me")}</label>
          </div>
          <Link className={styles.link} to="/password-reset">
            {t("forgot password")}
          </Link>
        </div>
        <button className={`${styles.button} ${styles.buttonGreen}`}>
          LOG IN
        </button>
      </form>
      <button
        className={`${styles.button} ${styles.buttonBlue}`}
        onClick={() => history.push("/signup")}
      >
        DON’T HAVE AN ACCOUNT? - SIGN UP
      </button>
      <WhitelabelLink className={styles.whitelabelLink} size="medium" />
    </div>
  );
};

export default Login;
