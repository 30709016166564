import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import firebase from "firebase/app";
import googleTagManager from "react-gtm-module";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "./index.scss";
import App from "./app/App";
import { store } from "./store";
import ContextProvider from "./context/ContextProvider";
import { notIframe } from "./utils/checkIfNotTheIFrame";

const INTERCOM_AVAILABLE_FOR = ["alice", "ocean-outdoor"];
const PROJECT = process.env.REACT_APP_PROJECT || "";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
});

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_ENV === "prod" &&
  INTERCOM_AVAILABLE_FOR.includes(PROJECT)
) {
  try {
    if (notIframe) {
      googleTagManager.initialize({
        gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || "",
      });
    }
  } catch (error) {
    console.dir(error);
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: "eng",
    fallbackLng: "eng",
    interpolation: {
      escapeValue: false,
    },
  });

// FOR DEV ENV TESTING
// googleTagManager.initialize({
//   gtmId: "GTM-TZ3ZGHB",
// });

ReactDOM.render(
  <>
    <Provider store={store}>
      <BrowserRouter
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        <ContextProvider>
          <App />
        </ContextProvider>
      </BrowserRouter>
    </Provider>
    {/* {process.env.NODE_ENV === "production" && process.env.REACT_APP_ENV === "prod" && <Drift appId={process.env.REACT_APP_DRIFT_ID!} />} */}
  </>,
  document.getElementById("root"),
);
