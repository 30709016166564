import { FC, memo, useMemo, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Link as RouterLink, useHistory } from "react-router-dom";

import styles from "./Sidebar.module.scss";
import context from "src/context";
import {
  SidebarArrow,
  EnterpriseDashboard,
  BookDemo,
  TimePlotter,
  Search,
  Dashboard,
  Logout,
  Logo,
  WhitelabelLogoHorizontal,
  WhitelabelLogoShort,
} from "src/icons";
import { useWindowWidth } from "src/hooks";
import { WhitelabelLink } from "src/components";
import { ROUTS } from "src/data/routs";

// Inner imports
import type { LinkType } from "./types";
import { ProfileLink, Link, FAQLink } from "./components";

export const Sidebar: FC = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();

  const { isSidebarOpen } = useContext(context);

  const [isNarrow, setIsNarrow] = useState<boolean>(true);

  const windowWidth: number = useWindowWidth();

  const isMobile: boolean = useMemo(() => windowWidth < 991, [windowWidth]);

  useEffect(() => {
    // Set initial narrow status
    if (isMobile) return setIsNarrow(true);

    let savedIsNarrow = true;

    try {
      let savedIsNarrowInLocalStorage =
        localStorage.getItem("Telescope.isNarrow") || "";

      const parsedSavedIsNarrowInLocalStorage = JSON.parse(
        savedIsNarrowInLocalStorage,
      );

      if (typeof parsedSavedIsNarrowInLocalStorage === "boolean") {
        savedIsNarrow = parsedSavedIsNarrowInLocalStorage;
      }
    } catch (err) {}

    setIsNarrow(savedIsNarrow);
  }, [isMobile]);

  const hasEnterpriseDashboards: boolean = useSelector(
    ({ company }: Store.State) => !!company.enterpriseDashboards.length,
  );

  const trackersLink = useMemo<LinkType>(() => {
    return {
      icon: Search,
      title: t("menu_trackers"),
      url: ROUTS.trackersPage,
      hasActiveStyles: history.location.pathname !== ROUTS.createTracker,
    };
  }, [history.location.pathname, t]);

  const topLinks: LinkType[] = useMemo(
    () => [
      {
        icon: Logo,
        title: t("menu_explore"),
        url: ROUTS.createTracker,
      },
      trackersLink,
      {
        icon: Dashboard,
        title: t("menu_dashboards"),
        url: ROUTS.dashboardsHomePage,
      },
      ...(hasEnterpriseDashboards
        ? [
            {
              icon: EnterpriseDashboard,
              title: t("menu_enterprise_dashboards"),
              url: ROUTS.enterpriseDashboardsPage,
            },
          ]
        : []),
      {
        icon: TimePlotter,
        title: t("menu_events"),
        url: ROUTS.eventsPage,
      },
    ],
    [hasEnterpriseDashboards, trackersLink, t],
  );

  const onNarrowClicked = () => {
    setIsNarrow((narrow) => {
      localStorage.setItem("Telescope.isNarrow", JSON.stringify(!narrow));
      return !narrow;
    });
  };

  return (
    <div
      className={cx(
        styles.sidebar,
        styles[isSidebarOpen ? "" : "sidebarClosed"],
      )}
    >
      <div
        className={cx(styles.content, { [styles.contentNarrow!]: isNarrow })}
      >
        <SidebarArrow className={styles.arrow} onClick={onNarrowClicked} />
        {!isMobile && (
          <RouterLink
            to="/home"
            className={styles.logoLink}
            style={{ textAlign: isNarrow ? "start" : "center" }}
          >
            {isNarrow ? <WhitelabelLogoHorizontal /> : <WhitelabelLogoShort />}
          </RouterLink>
        )}
        <ProfileLink className={cx(styles.link, styles.profileLink)} />
        <div className={styles.links}>
          <div className={styles.top}>
            {topLinks.map(
              ({
                icon,
                title,
                url,
                additionalClassName,
                hasActiveStyles = true,
              }) => (
                <Link
                  onClick={() => {
                    sessionStorage.removeItem("tablesSetting");
                  }}
                  key={title}
                  icon={icon}
                  title={title}
                  url={url}
                  className={cx(styles.link, additionalClassName)}
                  hasActiveStyles={hasActiveStyles}
                />
              ),
            )}
          </div>
          <div className={styles.bottom}>
            <Link
              icon={BookDemo}
              title={t("menu_book_demo")}
              url={ROUTS.demoBookingPage}
              className={styles.link}
            />
            <FAQLink className={styles.link} />
            <Link
              key={"logOut"}
              icon={Logout}
              title={t("menu_log_out")}
              url={ROUTS.logout}
              className={styles.link}
            />
            <WhitelabelLink isShort={!isNarrow} size="small" />
          </div>
        </div>
      </div>
    </div>
  );
});
